import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import colors from '../config/color';

const Tag = props => {
  const { slug, name } = props;
  return (
    <Link to={`category/${decodeURIComponent(slug)}/1/`}>
      <h3>{name}</h3>

      <style jsx>{`
        h3 {
          color: ${colors['dirt-brown']};
          font-size: 14px;
          border-radius: 18px;
          border: solid 1px ${colors['dirt-brown']};
          padding: 8px 12px;
          margin-right: 8px;
          display: inline-block;
          white-space: nowrap;
        }

        h3:hover {
          color: #ffffff;
          border-color: rgba(86, 63, 46, 0.1);
          background-color: ${colors['dirt-brown-70%']};
        }
      `}</style>
    </Link>
  );
};

Tag.propTypes = {
  slug: PropTypes.string,
  text: PropTypes.string,
};

Tag.defaultProps = {
  slug: '',
  text: '',
};

export default Tag;
