import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import 'lazysizes';
import Tag from './tag';
import colors from '../config/color';

const ProductCard = props => {
  const { slug, imageUri, imageAlt, title, categories } = props;

  return (
    <Link to={`${decodeURIComponent(slug)}/`}>
      <div key={slug} className="content">
        <div className="content-img">
          <img
            alt={imageAlt}
            className="lazyload"
            height={180}
            data-src={imageUri}
          />
        </div>

        <div className="content-title">
          <h1>{title}</h1>
          <div className="content-tag">
            {categories.map(categoryProps => (
              <Tag key={categoryProps.slug} {...categoryProps} />
            ))}
          </div>
        </div>

        <style jsx>{`
          .content {
            width: 268px;
            height: 365px;
            border-radius: 10px;
            background-color: ${colors['very-light-pink']};
            position: relative;
            -webkit-transition: 0s;
            transition: 0s;
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            transform: scale(1);
            -webkit-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
            -moz-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
            box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          }

          .content:hover {
            -webkit-transition: 0s;
            transition: 0s;
            transition-timing-function: ease-in-out;
            -webkit-transform: scale(1.05);
            -moz-transform: scale(1.05);
            transform: scale(1.05);
          }

          .content:active {
            opacity: 0.7;
            transition: 0s;
            transition-timing-function: ease-in-out;
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            transform: scale(1.1);
          }

          .content-img {
            width: 100%;
            height: 194px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            max-width: 100%;
          }

          .content-title {
            width: calc(100% - 40px);
            height: 171px;
            border-radius: 10px;
            background-color: #ffffff;
            position: absolute;
            bottom: 0px;
            padding: 0px 20px 0px 20px;
            -webkit-box-shadow: 0px 0px 5px -4px rgba(199, 199, 199, 0.69);
            -moz-box-shadow: 0px 0px 5px -4px rgba(199, 199, 199, 0.69);
            box-shadow: 0px 0px 5px -4px rgba(199, 199, 199, 0.69);
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .content-tag {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0 20px 0;
          }

          h1 {
            color: ${colors['dirt-brown']};
            width: 100%;
            font-size: 16px;
            line-height: 26px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          h1:hover {
            color: ${colors['dirt-brown-70%']};
          }

          @media (min-width: 0px) and (max-width: 615px) {
            .content {
              width: 100%;
              min-width: 330px;
              width: auto;
              height: 120px;
              margin: 0 20px 0 20px;
            }

            .content-tag {
              margin: 10px 0 0 0;
            }

            .content-img {
              width: 100px;
              height: 100%;
            }

            img {
              max-height: 80%;
            }

            .content-title {
              width: calc(100% - 100px - 40px);
              height: 100%;
              right: 0px;
            }

            h1 {
              -webkit-line-clamp: 2;
              visibility: visible;
            }
          }

          @media (min-width: 616px) and (max-width: 768px) {
          }
          @media (min-width: 769px) and (max-width: 992px) {
          }
          @media (min-width: 993px) and (max-width: 1200px) {
          }
        `}</style>
      </div>
    </Link>
  );
};

ProductCard.propTypes = {
  slug: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  title: PropTypes.string,
  categories: PropTypes.array,
};

ProductCard.defaultProps = {
  slug: '',
  imageUri: '',
  imageAlt: '',
  title: '',
  categories: [],
};

export default ProductCard;
