import React, { Component } from 'react';
import { graphql } from 'gatsby';
import 'lazysizes';
import cheerio from 'cheerio';
import PropTypes from 'prop-types';
import moment from 'moment';
import { AllHtmlEntities } from 'html-entities';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './post.css';
import colors from '../config/color';
import Tag from '../components/tag';
import GoTop from '../components/goTop';
import Product from '../components/product';
import Category from '../components/category';

const entities = new AllHtmlEntities();

class Post extends Component {
  constructor() {
    super();
    this.state = {
      content: '',
    };
    this.scrollPercentRounded = 0;
    this.showAd = true;
  }

  componentWillMount() {
    // 圖片 lazy load 延遲載入
    const post = this.props.data.wordpressPost;
    const $ = cheerio.load(post.content);
    $('img').each((i, elem) => {
      console.log($(elem).attr('src'));
      $(elem).attr('data-src', $(elem).attr('src'));
      $(elem).attr('data-srcset', $(elem).attr('srcset'));
      $(elem).removeAttr('src');
      $(elem).removeAttr('srcset');
      $(elem).addClass('lazyload');
    });

    this.setState({
      content: $('body').html(),
    });
  }

  componentDidMount() {
    this.detectScroll();
    this.detectScrollTitle();
  }

  detectScroll = () => {
    window.onscroll = function() {
      const scrollTop = document.documentElement.scrollTop;
      const docHeight = document.documentElement.offsetHeight;
      const winHeight = window.innerHeight;
      const scrollPercent = scrollTop / (docHeight - winHeight);
      const scrollPercentRounded = Math.round(scrollPercent * 100);
      if (
        scrollPercentRounded % 10 === 0 &&
        this.scrollPercentRounded !== scrollPercentRounded
      ) {
        this.scrollPercentRounded = scrollPercentRounded;
        // trackCustomEvent({
        //   category: 'Scroll Depth',
        //   action: decodeURI(window.location.href),
        //   label: `${scrollPercentRounded}%`,
        // });
      }
    };
  };

  detectScrollTitle = () => {
    if (!IntersectionObserver) return;
    let targets = [];
    targets = [...targets, ...document.querySelectorAll('article h3')];
    targets = [...targets, ...document.querySelectorAll('article h2')];

    let observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0) {
            trackCustomEvent({
              category: 'Scroll Appear Title',
              action: decodeURI(window.location.href),
              label: entry.target.innerHTML,
            });
          }
        });
      },
      {
        root: null,
        rootMargin: '150px',
        threshold: 1.0,
      },
    );

    for (let i = 0; i < targets.length; i++) {
      observer.observe(targets[i]);
    }
  };

  render() {
    const post = this.props.data.wordpressPost;
    const {
      categories: postCategories,
      modified,
      tags,
    } = this.props.data.wordpressPost;
    const relatedPost = this.props.data.allWordpressPost.edges;
    const categories = this.props.data.allWordpressCategory.edges;
    const siteMetadata = this.props.data.site.siteMetadata;
    const { content } = this.state;

    const breadcrumbs = [
      {
        data: postCategories.map(category => ({
          name: category.name,
          url: `category/${category.slug}/1/`,
        })),
      },
      {
        data: [
          {
            name: post.title,
            url: `/${decodeURIComponent(post.slug)}/`,
          },
        ],
      },
    ];
    // const metaTag = tags.map(o => ({
    //   property: 'article:tag',
    //   content: o.name,
    // }));
    const title = ''
    const description = ''
    return (
      <Layout breadcrumbs={breadcrumbs}>
        <SEO
          lang="zh-cmn-Hant"
          metaType={'article'}
          metaUrl={`${decodeURIComponent(post.slug)}`}
          title={entities.decode(title)}
          description={description}
          image={post.wordpressPost}
          graph={[
            {
              '@type': 'ImageObject',
              url: post.wordpressPost,
              // width: post.featured_media.media_details.width,
              // height: post.featured_media.media_details.height,
            },
            {
              '@type': 'WebPage',
              '@id': `${siteMetadata.siteUrl}${post.slug}/#post-title`,
              url: `${siteMetadata.siteUrl}${post.slug}/`,
              inLanguage: 'zh-TW',
              name: entities.decode(title),
              isPartOf: {
                '@id': siteMetadata.siteUrl,
              },
              primaryImageOfPage: {
                '@id': `${siteMetadata.siteUrl}${post.slug}/#post-title`,
              },
              datePublished: post.date,
              dateModified: post.modified,
              description: description,
            },
          ]}
          meta={[
            // ...metaTag,
            {
              propTypes: `article:published_time`,
              content: post.date,
            },
            {
              propTypes: `article:modified_time`,
              content: post.modified,
            },
            {
              propTypes: `og:updated_time`,
              content: post.modified,
            },
            {
              propTypes: `og:image:secure_url`,
              content: post.wordpressPost,
            },
            // {
            //   propTypes: `og:image:width`,
            //   content: post.featured_media.media_details.width,
            // },
            // {
            //   propTypes: `og:image:height`,
            //   content: post.featured_media.media_details.height,
            // },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:description`,
              content: description,
            },
            {
              name: `twitter:title`,
              content: entities.decode(title),
            },
            {
              name: `twitter:image`,
              content: post.wordpressPost,
            },
            {
              name: `twitter:image`,
              content: post.wordpressPost,
            },
          ]}
        />
        <div className="title">
          <h1 id="post-title">{post.title}</h1>
          <div className="title-bottom">
            <div className="content-tag">
              {postCategories.map(tagProps => (
                <Tag key={tagProps.slug} {...tagProps} />
              ))}
            </div>
            <div className="update">
              {`更新日期：${moment(modified).format('YYYY/MM/DD')}`}
            </div>
          </div>
        </div>
        <div className="gotop">
          <GoTop />
        </div>
        <article className="post-article-main">
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </article>

        <Product title={'你可能也喜歡'} edges={relatedPost} />
        <Category edges={categories} />
        <style jsx>{`
          .title {
            max-width: 900px;
            margin: auto;
            padding: 20px 20px 0 20px;
          }

          h1 {
            padding: 20px 0 10px 0;
            font-size: 26px;
            line-height: 1.6;
            color: ${colors['dirt-brown']};
          }

          .title-bottom {
            display: flex;
            flex-direction: row;
            align-items: center;
          }

          .update {
            margin-left: 5px;
            font-size: 14px;
            color: ${colors['dirt-brown']};
          }

          .gotop {
            position: fixed;
            right: 30px;
            bottom: 30px;
            z-index: 98;
          }
        `}</style>
      </Layout>
    );
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

Post.defaultProps = {};

export default Post;

export const postQuery = graphql`
  query($id: String!, $categoriesId: [String]!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: {
        id: { ne: $id }
        categories: { elemMatch: { id: { in: $categoriesId } } }
      }
      limit: 20
    ) {
      edges {
        node {
          title
          slug
          categories {
            wordpress_id
            id
            name
            slug
          }
          tags {
            id
            name
            slug
          }
          jetpack_featured_media_url
        }
      }
    }
    wordpressPost(id: { eq: $id }) {
      slug
      title
      content
      modified
      date
      categories {
        wordpress_id
        id
        name
        slug
      }
      tags {
        id
        name
        slug
      }
      jetpack_featured_media_url
    }
    allWordpressCategory {
      edges {
        node {
          wordpress_id
          name
          slug
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
