import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import colors from '../config/color';
import images from '../config/image';

const CategoryCard = props => {
  const { id, name, slug } = props;
  return (
    <>
      <li className="card-content">
        <Link
          style={{ textDecoration: 'none' }}
          to={`category/${decodeURIComponent(slug)}/1/`}
        >
          <div className="card-style">
            <img alt={name} style={{ width: 75 }} src={images.category[id]} />
            <h1 className="card-title">{name}</h1>
          </div>
        </Link>
      </li>
      <style jsx>{`
        .card-content {
          margin: 10px;
        }

        .card-style {
          width: 148px;
          height: 148px;
          -webkit-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          -moz-box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          box-shadow: 0px 0px 17px -4px rgba(199, 199, 199, 0.69);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .card-style:hover {
          -webkit-transition: 0s;
          transition: 0s;
          transition-timing-function: ease-in-out;
          -webkit-transform: scale(1.05);
          -moz-transform: scale(1.05);
          transform: scale(1.05);
        }

        .card-style:active {
          opacity: 0.7;
          transition: 0s;
          transition-timing-function: ease-in-out;
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          transform: scale(1.1);
        }

        .card-title {
          padding-top: 15px;
          color: ${colors['dirt-brown']};
          font-size: 16px;
        }
      `}</style>
    </>
  );
};

CategoryCard.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  slug: PropTypes.string,
};

CategoryCard.defaultProps = {
  id: 0,
  name: '',
  slug: '',
};

export default CategoryCard;
