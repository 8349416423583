import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';
import CategoryCard from './categoryCard';

const Category = props => {
  const { edges } = props;

  return (
    <div>
      <ul className="category-content">
        {edges.map(({ node }) => (
          <CategoryCard
            id={node.wordpress_id}
            name={node.name}
            slug={node.slug}
            key={node.slug}
          />
        ))}
      </ul>

      <style jsx>{`
        h1 {
          font-size: 24px;
          color: ${colors['dirt-brown']};
          margin: 80px 0px 20px 0px;
          display: flex;
          justify-content: center;
        }

        .category-content {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          padding: 0 5% 0 5%;
        }
      `}</style>
    </div>
  );
};

Category.propTypes = {
  edges: PropTypes.array,
};

Category.defaultProps = {
  edges: [],
};

export default Category;
