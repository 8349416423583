import React from 'react';
import PropTypes from 'prop-types';
import 'lazysizes';
import colors from '../config/color';
import { FaLine, FaFacebookSquare, FaCaretSquareUp } from 'react-icons/fa';

const GoTop = props => {
  const {} = props;
  return (
    <div className="action-contnet">
      <div
        className="icon top-icon"
        onClick={() => {
          const scrollToTop = () => {
            const c =
              document.documentElement.scrollTop || document.body.scrollTop;
            if (c > 0) {
              window.requestAnimationFrame(scrollToTop);
              window.scrollTo(0, c - c / 8);
            }
          };
          scrollToTop();
        }}
      >
        <FaCaretSquareUp style={{ height: 40, width: 40 }} />
      </div>
      {/* <div className="icon line-icon">
        <FaLine style={{ height: 40, width: 40 }} />
      </div>
      <div className="icon fb-icon">
        <FaFacebookSquare style={{ height: 40, width: 40 }} />
      </div> */}

      <style jsx>{`
        .action-contnet {
          color: ${colors['dirt-brown']};
          display: flex;
          flex-direction: column;
        }

        .icon:hover {
          color: ${colors['dirt-brown-70%']};
        }
      `}</style>
    </div>
  );
};

GoTop.propTypes = {};

GoTop.defaultProps = {};

export default GoTop;
